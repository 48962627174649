import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PostjobEmployer } from "../../../store/slices/employerprofile";
import { getCuisines, getQualification } from "../../../store/slices/config";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { API_PATHS } from "../../../utils/constants/api.constants";
import { getemployerProfile, GetjobEmployer } from "../../../store/slices/employerprofile";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import AdditionalPerks from "../../common/AdditionalPerks";

function PostJob() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Post Job";
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelcetedCisines] = useState([]);
  const [education, setEducation] = useState([]);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [mydata, setMyData] = useState([]);
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);
  const [inputcityError, setcityInputError] = useState(false);
  const inputRef = useRef(null);
  const disignationRef = useRef(null);
  const salaryRef = useRef(null);
  const urgencyRef = useRef(null);
  const experienceRef = useRef(null);
  const noofOpeningsRef = useRef(null);
  const cuisineRef = useRef(null);
  const foodRef = useRef(null);
  const accommodationRef = useRef(null);
  const pincodeRef = useRef(null);
  const jobdescRef = useRef(null);
  const cityinputRef = useRef(null);

  useEffect(() => {
    // Check if the input field should be focused
    if (isEditing && inputRef.current) {
      inputRef.current.focus();

      // Move the cursor to the end of the input value
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isEditing]);



  useEffect(() => {
    if (typeof id !== "undefined") {
      dispatch(GetjobEmployer(id))
        .unwrap()
        .then((data) => {
          let jobData = data.getalljobemployer?.data[0];
          if (typeof jobData !== "undefined") {
            const createJobDataKeys = [
              "designation",
              "description",
              "qualification",
              "urgency",
              "contactNumber",
              "whatsappUpdate",
              "visibility",
              "food",
              "accommodation",
              "pincode",
              "salary",
              "experience",
              "openings",
              "cuisines",
              "dishes",
              "location",
              "locationCoordinates",
            ];
            const createJobData = Object.fromEntries(
              createJobDataKeys.map((key) => [key, jobData[key]])
            );
            setData((prevData) => ({
              ...prevData,
              status:1,
              ...createJobData,
              weekOffs: jobData.weekOffs === 1,
              providentFund: jobData.providentFund === 1,
              incentives: jobData.incentives === 1,
              insurance: jobData.insurance === 1,
            }));
            setSelcetedCisines(createJobData?.cuisines);
          }
        })
        .catch(({ error }) => {
          console.log(error);
        });
    }
  }, [dispatch, id]);

const fetchCuisines = async () => {
    try {
      const data = await dispatch(getCuisines()).unwrap();
      setCuisines(data.user.data);
    } catch (error) {
      console.error("Error fetching cuisines:", error);
    }
  };

const fetchQualifications = async () => {
    try {
      const data = await dispatch(getQualification()).unwrap();
      setEducation(data.user.data);
    } catch (error) {
      console.error("Error fetching qualifications:", error);
    }
  };

const fetchEmployerProfile = async () => {
    try {
      const data1 = await dispatch(getemployerProfile()).unwrap();
      setMyData(data1.user.data);
      setData((prevData) => ({
        ...prevData,
        contactNumber: data1.user.data.mobile,
      }));
    } catch (error) {
      console.error("Error fetching employer profile:", error);
    }
  };

useEffect(() => {
    fetchCuisines();
    fetchQualifications();
    fetchEmployerProfile();
}, [dispatch]);


useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (
        !document.querySelector(
          'script[src^="https://maps.googleapis.com/maps/api/js"]'
        )
      ) {
        console.log("Loading Google Maps API script");
        const googleMapsScript = document.createElement("script");
        googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_PATHS.googlemapsapikey}&libraries=places`;
        googleMapsScript.async = true;
        googleMapsScript.defer = true;
        googleMapsScript.onload = initializeAutocomplete;
        googleMapsScript.onerror = () => {
            console.error("Failed to load Google Maps API script");
          };
          document.body.appendChild(googleMapsScript);
        } else {
        initializeAutocomplete();
      }
    };

    const initializeAutocomplete = () => {
      if (cityinputRef.current) {
        const autoCompleteCity = new window.google.maps.places.Autocomplete(
          cityinputRef.current,
          {
            componentRestrictions: { country: "in" },
          }
        );

        autoCompleteCity.addListener("place_changed", () => {
          const place = autoCompleteCity.getPlace();
          if (!place.geometry || !place.geometry.location) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              location: "Please select location from the suggestion drop-down",
            }));
          } else {
            setcityInputError(false);
            setErrors((prevErrors) => ({
              ...prevErrors,
              location: "",
            }));
            const coordinates = [
              place.geometry.location.lng(),
              place.geometry.location.lat(),
            ];
            setcity(coordinates, place.name);
          }
        });

        cityinputRef.current.addEventListener("blur", () => {
          const place = autoCompleteCity.getPlace();
          const inputValue = cityinputRef.current.value.trim();
          if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
            setcityInputError(true);
            setErrors((prevErrors) => ({
              ...prevErrors,
              location: "Please select location from the suggestion drop-down",
            }));
          }
        });
      }
    };

    loadGoogleMapsScript();

    return () => {
      if (cityinputRef.current) {
        cityinputRef.current.removeEventListener("blur", () => {});
      }
    };
}, [API_PATHS.googlemapsapikey, cityinputRef]);

  const [data, setData] = useState({
    designation: "",
    salary: "",
    experience: "",
    openings: "",
    urgency: "",
    contactNumber: "",
    whatsappUpdate: 1,
    status: 1,
    description: "",
    visibility: 1,
    pincode: "",
    food: "",
    accommodation: "",
    qualification: "",
    dishes: "",
    cuisines: "",
    location: "",
    locationCoordinates: {
      type: "Point",
      coordinates: [],
    },
    weekOffs: false,
    providentFund: false,
    incentives: false,
    insurance: false,
  });

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^[a-zA-Z0-9@&'’ -]{3,50}$/.test(name);
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const isValidcurrentCity = (location) => {
    return /^[a-zA-Z0-9\-',&@ ]{3,100}$/.test(location);
  };

  const isValidPincode = (pincode) => {
    // validating the name with one cap letter and 6 char of length
    return /^[1-9][0-9]{5}$/.test(pincode);
  };

  const isValidExperienceVal = (Experience) => {
    return /^(0?[1-9]|[1-4][0-9]|50)$/.test(Experience);
  };

  const isValidSalary = (SalaryVal) => {
    return /^(?:1[89]\d{3,}|[2-9]\d{4,}|100000|[1-9]\d{5,})$/.test(SalaryVal);
  };

  const isValidOpenings = (openingsVal) => {
    return /^(0[1-9]|[1-9][0-9]?|100)$/.test(openingsVal);
  };

  const isValidDescription = (descriptionVal) => {
    return /^(?!\s)[\s\S]{3,1000}$/.test(descriptionVal);
  };

  const isValidDish = (dishes) => {
    return /^[a-zA-Z0-9\- ',&@]{2,100}$/.test(dishes);
  };

  var cuisinoptions = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const setcity = (coordinates, city) => {
    setData((prevData) => ({
      ...prevData,
      location: city,
      locationCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  //done
  const submitHandler = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!data.designation) {
      validationErrors.designation = "Please enter designation";
    } else if (!isValidName(data.designation)) {
      validationErrors.designation = "Please enter a valid designation";
    }
    if (!data.salary) {
      validationErrors.salary = "Please enter salary";
    } else if (!isValidSalary(data.salary)) {
      validationErrors.salary =
        "We only post jobs with a minimum salary Rs.18000";
    }

    if (data.dishes !== "" && !isValidDish(data.dishes)) {
      validationErrors.dishes = "Please enter valid dishes";
    }

    if (!data.experience) {
      validationErrors.experience = "Please enter experience";
    } else if (!isValidExperienceVal(data.experience)) {
      validationErrors.experience =
        "Please enter a valid experience. Max. 50 years experience is allowed";
    }

    if (!data.urgency) {
      validationErrors.urgency = "Please select urgency";
    }
    if (!data.contactNumber) {
      validationErrors.contactNumber = "Please enter your contact number";
    } else if (!isValidmobile(data.contactNumber)) {
      validationErrors.mobileNumber = "Please enter valid mobile number";
    }
    if (!data.description) {
      validationErrors.description = "Please enter job description";
    } else if (!isValidDescription(data.description)) {
      validationErrors.description = "Please enter atleast 3 charaters";
    }
    if (!data.pincode) {
      validationErrors.pincode = "Please enter pincode";
    } else if (!isValidPincode(data.pincode)) {
      validationErrors.pincode = "Pincode must be 6 digits";
    }

    if (data.food === "") {
      validationErrors.food = "Please select food";
    }
    if (data.accommodation === "") {
      validationErrors.accommodation = "Please select accommodation";
    }

    if (!data.location) {
      validationErrors.location = "Please enter location";
    } else if (!isValidcurrentCity(data.location) || inputcityError) {
      validationErrors.location = "Please enter valid location";
    }

    const isValid = validateCuisines(selectedCuisines);
    if (!isValid) {
      validationErrors.cuisines = "Please enter at least one cuisine";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      if (validationErrors.designation) {
        disignationRef.current.focus();
      } else if (validationErrors.salary) {
        salaryRef.current.focus();
      } else if (validationErrors.urgency) {
        urgencyRef.current.focus();
      } else if (validationErrors.experience) {
        experienceRef.current.focus();
      } else if (validationErrors.openings) {
        noofOpeningsRef.current.focus();
      } else if (validationErrors.contactNumber) {
        inputRef.current.focus();
      } else if (validationErrors.cuisines) {
        cuisineRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.food) {
        foodRef.current.focus();
      } else if (validationErrors.accommodation) {
        accommodationRef.current.focus();
      } else if (validationErrors.pincode) {
        pincodeRef.current.focus();
      } else if (validationErrors.location) {
        cityinputRef.current.focus();
      } else if (validationErrors.description) {
        jobdescRef.current.focus();
      }
      return;
    } else {
      const numberph = parseInt(data.contactNumber);
      const pin = parseInt(data.pincode);
      const salary = parseInt(data.salary);
      const exp = parseInt(data.experience);
      const vac = parseInt(data.openings);
      const item = {
        ...data,
        designation: data?.designation || undefined,
        urgency: data?.urgency || undefined,
        status: data?.status || undefined,
        description: data?.description || undefined,
        visibility: data?.visibility,
        whatsappUpdate: data?.whatsappUpdate,
        food: data?.food,
        accommodation: data?.accommodation,
        qualification: data?.qualification || undefined,
        dishes: data?.dishes || undefined,
        location: data?.location || undefined,
        cuisines: selectedCuisines || undefined,
        locationCoordinates: data?.locationCoordinates || undefined,
        weekOffs: data.weekOffs ? 1 : 0,
        providentFund: data.providentFund ? 1 : 0,
        incentives: data.incentives ? 1 : 0,
        insurance: data.insurance ? 1 : 0,
        contactNumber: numberph || undefined,
        pincode: pin || undefined,
        salary: salary || undefined,
        openings: vac || undefined,
        experience: exp || undefined,
      };
      setisDisabled(true);
      dispatch(PostjobEmployer(item))
        .unwrap()
        .then((data) => {
          setModalMessage("Job posted succesfully");
          setsuccessModal(true);
          setisDisabled(false);
          setTimeout(() => {
            navigate("/employer/manage-jobs");
          }, 3000);
        })
        .catch(({ message }) => {
          setisDisabled(false);
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleVisibleChange = (e) => {
    setData({
      ...data,
      visibility: parseInt(e.target.value),
    });
  };

  const handleAccoChange = (e, val) => {
    setErrors({
      ...errors,
      accommodation: "",
    });
    if (val == "yes") {
      setData({
        ...data,
        accommodation: 1,
      });
    } else {
      setData({
        ...data,
        accommodation: 0,
      });
    }
  };

  const handleFoodChange = (e, val) => {
    setErrors({
      ...errors,
      food: "",
    });

    if (val == "yes") {
      setData({
        ...data,
        food: 1,
      });
    } else {
      setData({
        ...data,
        food: 0,
      });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "designation":
        setData({
          ...data,
          designation: value,
        });
        validationErrors[name] =
          value === ""
            ? "Please enter designation"
            : value !== "" && !isValidName(value)
            ? "Please enter a valid designation"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;

      case "dishes":
        setData({
          ...data,
          dishes: value,
        });
        validationErrors[name] =
          value.trim() !== "" && !isValidDish(value)
            ? "Please enter valid dishes"
            : "";
        break;

      case "salary":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setData({
          ...data,
          salary: value,
        });
        validationErrors[name] =
          value === "" || !isValidSalary(value)
            ? "We only post jobs with a minimum salary Rs.18000"
            : "";
        if (value !== "" && isValidSalary(value)) {
          validationErrors[name] = "";
        }
        break;
      case "urgency":
        setData({
          ...data,
          urgency: value,
        });
        validationErrors[name] = value === "" ? "Please enter urgency" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "experience":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        setData({
          ...data,
          experience: value,
        });
        validationErrors[name] =
          value === "" || !isValidExperienceVal(value)
            ? "Please enter a valid experience. Max. 50 years experience is allowed"
            : "";
        if (value !== "" && isValidExperienceVal(value)) {
          validationErrors[name] = "";
        }
        break;
      case "openings":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 3) {
          value = value.slice(0, 3);
        }
        setData({
          ...data,
          openings: value,
        });
        validationErrors[name] =
          value !== "" && !isValidOpenings(value)
            ? "Please enter a value between 1 to 100"
            : "";
        if (value !== "" && isValidOpenings(value)) {
          validationErrors[name] = "";
        }
        break;
      case "contactNumber":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setData({
          ...data,
          contactNumber: value,
        });
        setMyData({
          ...mydata,
          mobile: value,
        });
        validationErrors[name] =
          value === "" || !isValidmobile(value)
            ? "Please enter valid contact number"
            : "";
        if (!value === "" && isValidmobile(value)) {
          delete validationErrors[name];
        }
        break;
      case "description":
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
        setData({
          ...data,
          description: value,
        });
        validationErrors[name] =
          value === "" || !isValidDescription(value)
            ? "Please enter atleast 3 chracters"
            : "";
        if (!value === "" && isValidDescription(value)) {
          delete validationErrors[name];
        }
        break;

      case "qualification":
        setData({
          ...data,
          qualification: value,
        });
        validationErrors[name] =
          value === "" ? "Please enter qualification" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "dishes":
        setData({
          ...data,
          dishes: value,
        });
        validationErrors[name] = value === "" ? "Please enter dishes" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "location":
        setData({
          ...data,
          location: value,
        });
        validationErrors[name] =
          value === "" ? "Please enter valid location" : "";
        if (!value === "" && isValidcurrentCity(value)) {
          delete validationErrors[name];
        }
        break;
      case "pincode":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setData({
          ...data,
          pincode: value,
        });
        validationErrors[name] =
          value === "" || !isValidPincode(value) ? "Please enter pincode" : "";
        if (!value === "" && isValidPincode(value)) {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }

    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleCuisineChange = (selectedOptions) => {
    setSelcetedCisines(selectedOptions);
    const isValid = validateCuisines(selectedOptions);
    setErrors((prevErrors) => ({
      ...prevErrors,
      cuisines: isValid ? "" : "Please select at least one cuisine",
    }));
  };

  const validateCuisines = (selectedOptions) => {
    return selectedOptions.length > 0;
  };
  const recieveWatsapp = (e) => {
    setData({
      ...data,
      whatsappUpdate: e.target.checked ? 1 : 0,
    });
  };
  const HandleClick = (field, value) => {
    setData((prev) => ({ ...prev, [field]: value }));
  };
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/employer/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; Post Job
      </div>
      {mydata?.pointsData?.currentJobPoints === 0 && (
        <Row className="card mt-4 p-5">
          <Col lg={12} xs={12} md={12} align="center">
            <h3>You don't have enough points to post job</h3>
            <br />
            <button
              onClick={() => navigate("/employer/plans")}
              className="saveBtn"
            >
              Buy Plan
            </button>
          </Col>
        </Row>
      )}
      {mydata?.pointsData?.currentJobPoints !== 0 && (
        <div>
          <div className="post_job">
            <Form autoComplete="off" noValidate onSubmit={submitHandler}>
              <Row className="d-flex justify-content-center ">
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Designation *</label>
                    <Form.Control
                      name="designation"
                      value={data.designation}
                      onChange={handleChange}
                      isInvalid={!!errors.designation}
                      placeholder="e.g Head Chef, Sous Chef"
                      className="input1"
                      type="text"
                      maxLength={50}
                      ref={disignationRef}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.designation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Salary *</label>
                    <Form.Control
                      placeholder="18000"
                      name="salary"
                      type="number"
                      onChange={handleChange}
                      value={data.salary}
                      isInvalid={!!errors.salary}
                      className="input1"
                      ref={salaryRef}
                      onWheel={(e) => e.target.blur()}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.salary}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Urgency *</label>

                    <Form.Control
                      as="select"
                      name="urgency"
                      className="input1"
                      value={data.urgency}
                      onChange={handleChange}
                      isInvalid={!!errors.urgency}
                      ref={urgencyRef}
                      isValid={errors.urgency}
                    >
                      <option value="" selected disabled hidden>
                        Select
                      </option>
                      <option>Immediately</option>
                      <option>15 Days</option>
                      <option>1 Month</option>
                      <option>2 Months</option>
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                      {errors.urgency}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Experience *</label>
                    <Form.Control
                      name="experience"
                      value={data.experience}
                      placeholder="Enter number of years"
                      className="input1"
                      isInvalid={!!errors.experience}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      ref={experienceRef}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.experience}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">No. of Openings</label>
                    <Form.Control
                      value={data.openings}
                      name="openings"
                      placeholder="1 or 2"
                      isInvalid={!!errors.openings}
                      type="text"
                      onChange={handleChange}
                      ref={noofOpeningsRef}
                      className="input1"
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.openings}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Contact Number *</label>

                    <Form.Control
                      name="contactNumber"
                      value={mydata.mobile}
                      placeholder="contact number"
                      isInvalid={!!errors.contactNumber}
                      type="text"
                      pattern="[0-9]*"
                      className="input1"
                      onChange={handleChange}
                      readOnly={!isEditing}
                      ref={inputRef}
                    ></Form.Control>

                    {!isEditing ? (
                      <p
                        style={{
                          color: "#a020f0",
                          cursor: "pointer",
                          fontStyle: "italic",
                          marginTop: "-25px",
                          marginLeft: "90%",
                        }}
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </p>
                    ) : (
                      <p
                        style={{
                          color: "#a020f0",
                          cursor: "pointer",
                          marginTop: "-25px",
                          marginLeft: "90%",
                        }}
                      >
                        Edit
                      </p>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.contactNumber}
                    </Form.Control.Feedback>
                    <div className="d-flex justify-content align-items-center mt-2">
                      <Form.Check
                        type="checkbox"
                        checked={data.whatsappUpdate}
                        onChange={recieveWatsapp}
                        style={{ color: "#0051EA" }}
                        id="myCheckbox"
                        label="Receive applications on WhatsApp"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex justify-content-center mt-2">
                <h6 className="text-start ms-2" style={{ color: "#ff5c09" }}>
                  Skills and Education
                </h6>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-1 label">Cuisines *</label>
                    <div ref={cuisineRef}>
                      <Typeahead
                        id="basic-typeahead-multiple"
                        labelKey="North Indian, South Indian"
                        multiple
                        className="input1"
                        onChange={handleCuisineChange}
                        options={cuisinoptions}
                        placeholder="North Indian, South Indian"
                        selected={selectedCuisines}
                      />
                    </div>
                    <p style={{ color: "#DC3545", fontSize: "14px" }}>
                      {errors.cuisines}
                    </p>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-3 label">Dishes</label>
                    <Form.Control
                      value={data.dishes}
                      name="dishes"
                      placeholder="Nan, Paneer"
                      className="input1"
                      isInvalid={!!errors.dishes}
                      type="text"
                      onChange={handleChange}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.dishes}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Qualification</label>
                    <Form.Control
                      as="select"
                      name="qualification"
                      className="input1"
                      value={data.qualification}
                      onChange={handleChange}
                      isInvalid={!!errors.qualification}
                    >
                      <option value="" selected disabled hidden>
                        Select
                      </option>
                      {education.map((edudata, index) => (
                        <option key={index} value={edudata.iqualificationNamed}>
                          {edudata.qualificationName}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.qualification}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} className="p-3" />
              </Row>
              {/*--------------- */}

              <Row className="d-flex justify-content-center ">
                <h6
                  className="text-start mt-4 ms-2"
                  style={{ color: "#ff5c09" }}
                >
                  Benefits
                </h6>
                <Col lg={6} md={6} className="p-1">
                  <Form.Group className="justify-content-start align-items-center">
                    <label className="mb-3 ms-3 label">Food *</label>

                    <Row className="ms-1">
                      <Col lg={3} md={3} xs={6}>
                        <div>
                          <Form.Check
                            type="switch"
                            id="foodyes"
                            label="Yes"
                            ref={foodRef}
                            checked={data.food === 1}
                            onChange={(e) => handleFoodChange(e, "yes")}
                          />
                        </div>
                      </Col>
                      <Col lg={3} md={3} xs={6}>
                        <div>
                          <Form.Check
                            type="switch"
                            id="foodno"
                            label="No"
                            checked={data.food === 0}
                            onChange={(e) => handleFoodChange(e, "no")}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                  <p
                    style={{
                      color: "#DC3545",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                    className="ms-3"
                  >
                    {errors.food}
                  </p>
                </Col>
                <Col lg={6} md={6} className="p-1">
                  <Form.Group className="justify-content-start align-items-center">
                    <label className="mb-3 ms-3 label">Accommodation *</label>

                    <Row className="ms-1">
                      <Col lg={3} md={3} xs={6}>
                        <Form.Check
                          type="switch"
                          id="accommodation-yes"
                          label="Yes"
                          ref={accommodationRef}
                          name="accommodation"
                          checked={data.accommodation === 1}
                          onChange={(e) => handleAccoChange(e, "yes")}
                        />
                      </Col>
                      <Col lg={3} md={3} xs={6}>
                        <Form.Check
                          type="switch"
                          id="accommodation-no"
                          label="No"
                          name="accommodation"
                          checked={data.accommodation === 0}
                          onChange={(e) => handleAccoChange(e, "no")}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <p
                    style={{
                      color: "#DC3545",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                    className="ms-3"
                  >
                    {errors.accommodation}
                  </p>
                </Col>
              </Row>
              {/*--------------- */}

              <Row className="d-flex justify-content-center">
                <AdditionalPerks HandleClick={HandleClick} data={data} />
              </Row>

              <Row className="d-flex justify-content-center  mt-3">
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Pincode *</label>
                    <Form.Control
                      value={data.pincode}
                      name="pincode"
                      placeholder="110011"
                      className="input1"
                      isInvalid={!!errors.pincode}
                      type="number"
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                      ref={pincodeRef}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.pincode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group>
                    <label className="mb-2 label">Location *</label>
                    <Form.Control
                      value={data.location}
                      name="location"
                      placeholder="Bangalore, Mumbai"
                      isInvalid={!!errors.location}
                      type="text"
                      ref={cityinputRef}
                      className="input1"
                      onChange={handleChange}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.location}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="kindOfStand" className="mt-2">
                    <Form.Check
                      type="radio"
                      id="visibility-all-india"
                      name="visibility"
                      value="1"
                      label="Visible to all India (Recommended)"
                      checked={data.visibility === 1}
                      onChange={(e) => handleVisibleChange(e)}
                    />

                    <Form.Check
                      type="radio"
                      id="visibility-selected-location"
                      name="visibility"
                      value="0"
                      label="Visible to same location only"
                      checked={data.visibility === 0}
                      onChange={(e) => handleVisibleChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/*--------------- */}

              <Row>
                <Col lg={12} sm={12}>
                  <Form.Group className="my-4">
                    <label className="mb-2 label">Job Description *</label>
                    <Form.Control
                      value={data.description}
                      name="description"
                      placeholder="About the job"
                      className="input1"
                      isInvalid={!!errors.description}
                      as="textarea"
                      style={{ resize: "none" }}
                      rows={1}
                      maxLength={1000}
                      onChange={handleChange}
                      ref={jobdescRef}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row
                className="justify-content-center align-items-center mt-3"
                align="center"
              >
                <Col lg={6} sm={12}>
                  <Button onClick={cancelHandler} className="cancelBtn">
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    disabled={isdisabled}
                    type="submit"
                    className="saveBtn"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
      <br />
      <br />
      <br />
    </>
  );
}

export default PostJob;